
.modal{
    background-color: rgba(0,0,0, 0.2);
  }

.pointer{
  cursor: pointer;
}
.video-player-ss{
  width: 100% !important;
  height: auto;
}
.fw-400{
  font-weight: 400 !important;
}

.fs-p9{
  font-size: 0.9rem !important;
}
.ss-scroll{
  max-height: 75vh !important;
  overflow-x: auto;
}

.ss-scroll::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 6px;
	background-color: rgba(255, 255, 255, 0.1);
}

.ss-scroll::-webkit-scrollbar
{
	width: 6px;
	background-color: rgba(255, 255, 255, 0.1)
}

.ss-scroll::-webkit-scrollbar-thumb
{
	border-radius: 6px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: rgba(0,0,0,.1);
}


.ss-pos-rel{
  position: relative !important;
}
.ss-zid-5{
  z-index: 0 !important;
  display: block !important;
}

.ss-bold{
  font-weight: 700 !important;
}
.bg-ss {
  color: #fff !important;
  background-color: #000 !important;
  border-color: #000 !important; /* 712cf9 */
  padding: 10px;
}
.text-highlight-warning{
  background-image: linear-gradient(to bottom, rgba(255, 230, 69, 0.5), rgba(255, 230, 69, 0.5));

}
.text-offer{
  color: #712cf9 !important; 
}

.bg-ss-dark{
background-color: #000 !important;
}

.ss-link{
  color: rgba(255, 255, 255, 0.8) !important;
}
.ss-link:hover{
  color: rgba(255, 255, 255, 1) !important;
}
.ss-link-btn{
  color: rgba(255, 255, 255, 0.8) !important;
}
.ss-link-btn:hover{
  color: #000 !important;
}
.m-ss-1{
  margin: 2px !important;
}
.ss-link-btn-color{
  color: rgba(0, 0, 0, 0.8) !important;
}
.ss-link-btn-color:hover{
  color: #000 !important;
}